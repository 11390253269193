import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import {
  FooterRecCard,
  IndustriesStatic,
} from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/for-security/",
    label: "For Security",
  },
]

const resourcesList = [
  {
    title: "VigilantMDR ",
    subtitle: "Managed Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR ",
    subtitle: "Managed Vulnerability Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Recent Attacks",
    subtitle: "Require an Updated Mindset",
    LinkUr: "/blog-pages/recent-attacks/",
    cat: "BLOGS",
  },
]

const ForSecurity = () => (
  <Layout>
    <Seo title="For Security" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-1 ">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>CONFIDENT SECURITY</h1>
            <h4>GAIN AND SECURE THE HIGHER GROUND</h4>
            <p className="content">
              Collaborative cyber defense with Vigilant provides unparalleled
              security from on-prem to cloud and remote workstations:
              State-of-the-art radical visibility, analysis and defense.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  get started today
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <div>
      <section className="section-cards-3--for-security">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul className="card-feature-list">
                <li className="card">
                  <Link to="/service/">
                    <ul className="feature-card">
                      <li className="feature-img">
                        <StaticImage
                          src="../images/for-security/utdIcon.png"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          alt="Service Placeholder"
                          className="img-fluid text-center"
                        />
                      </li>
                      <li className="feature-title">UNIFIED TEAM DEPLOYMENT</li>
                      <li className="feature-content">
                        With Vigilant, you access a fully armed cyberwar team
                        that partners with you shoulder-to-shoulder.
                      </li>
                    </ul>
                  </Link>
                  <div className="d-chase"></div>
                </li>
                <li className="card">
                  <Link to="/the-platform/">
                    <ul className="feature-card">
                      <li className="feature-img">
                        <StaticImage
                          src="../images/for-security/rvLogo.png"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          alt="Service Placeholder"
                          className="img-fluid text-center"
                        />
                      </li>
                      <li className="feature-title">RADICAL VISIBILITY</li>
                      <li className="feature-content">
                        Sensors within and visibility in and around the
                        perimeter. From the Vigilant vantage point, you will
                        take the higher ground, own it and keep it.
                      </li>
                    </ul>
                  </Link>
                  <div className="d-chase"></div>
                </li>
                <li className="card">
                  <Link to="/blog-pages/breach-response">
                    <ul className="feature-card">
                      <li className="feature-img">
                        <StaticImage
                          src="../images/for-security/brpLogo.png"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          alt="Service Placeholder"
                          className="img-fluid text-center"
                        />
                      </li>
                      <li className="feature-title">BREACH RESPONSE POLICY</li>
                      <li className="feature-content">
                        More than Incident Response Insurance (unfixed costs),
                        with Vigilant you become certified and receive an
                        Unlimited Breach Response Policy as a part of our MDR
                        Service (fixed and promised).
                      </li>
                    </ul>
                  </Link>
                  <div className="d-chase"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="grid--bg --for-it-ops-3">
        <section className="section-col-2-img--for-security">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h2>INTIATIVES</h2>
                <h6>EVOLVING THREAT LANDSCAPE</h6>
                <p>
                  It’s necessary to be just as ruthless and adaptive as the
                  evolving threat actors
                </p>
                <p>
                  Our advanced Managed Detection and Response (MDR) and Attack
                  Surface Reduction capture the higher ground vantage point –
                  and keep it.
                </p>
                <Link to="/evolving-threat/" className="nav-section--cta-btn">
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      learn more
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>
                </Link>
              </div>
              <div className="section-col-2">
                <div className="feature-box--for-security">
                  <Link to="/secure-the-high-ground/">
                    <ul className="feature-box--link">
                      <li className="feature-box--img">
                        <StaticImage
                          src="../images/landing/Vigilant_HigherGroundCover-for-Web.png"
                          quality={95}
                          formats={["AUTO", "WEBP"]}
                          layout="fullWidth"
                          alt="8 Questions"
                          className="img-fluid"
                        />
                      </li>
                      <li className="feature-box--title"></li>
                      <li className="feature-box--cta">
                        <span className="readNow">READ NOW </span>
                        <i className="block-chase-icon"></i>
                      </li>
                    </ul>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-4-facts">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h5>SECURITY MATURITY</h5>
                <p className="content">
                  Continuously level up your defense. Vigilant is your co-vested
                  partner in advanced threat assessment, evolving vulnerability
                  detection and Team-Centered support.
                </p>
              </div>
              <div className="section-col-1">
                <h5>ACCOUNT TAKEOVER PROTECTION</h5>
                <p className="content">
                  Adobe reported that white-collar employees spend 352
                  minutes/day on email. To stop slow and insidious incursions by
                  threat actors, you need a partner who can proactively uncover
                  the clues that signal a potential takeover – before it
                  happens.
                </p>
              </div>
              <div className="section-col-1">
                <h5>
                  RISK REDUCTION
                  <br />
                </h5>
                <p className="content">
                  Threat actors are armed with Exploit and Malware Kits designed
                  to harm you. Vigilant studies those kits, compares them to
                  your vulnerability landscape (MVDR) and tests them with our
                  benevolent hackers (Penetration Testing) to reduce risk before
                  an attack.
                </p>
              </div>
              <div className="section-col-1">
                <h5>SECURE SOC</h5>
                <p className="content">
                  Vigilant provides an advanced US-Based Security Operations
                  Center (SOC) with 24/7/365 visibility on your company inside
                  and out, with comprehensive knowledge of industry-specific
                  threat tactics. Bonus: We always have our eyes on the latest
                  global security trends to stay one step ahead.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-title-top --for-security">
        <div className="container">
          <div className="row">
            <div className="section-col-2">
              <hr />
              <h3>INTEGRATED SECURITY SOLUTION</h3>
              <h6>YOUR PERPETUAL ANALYSIS TEAM</h6>
              <p className="p-quote">
                <em>
                  “For tactical reasons, you are advised to take the higher
                  ground so the enemy is forced to try attacking from a lower
                  position.”
                  <span className="p-quote--author">
                    - Sun Tzu’s The Art of War
                  </span>
                </em>
              </p>

              <p>
                When talking about security, we begin with our team of Sentinels
                and Hunters followed by our state-of-the-art and proprietary
                technologies that undergird our Managed Detection and Response
                (MEDR, MNDR, MVDR, Penetration Testing, etc.). When you partner
                with Vigilant, relationships and service is the priority. Our
                team becomes your team.
              </p>
              <p>
                Threat actors are gritty criminals who will stop at nothing to
                harm companies. The Vigilant team (developers, technicians and
                hunters) are just as gritty – overseeing a seamless, integrated,
                ever-watchful MDR Service. These are real humans, fully invested
                in protecting you and your company’s assets. We are the only
                company in the industry that promises Unlimited Breach Response.
                And we will never sell your data or use your logo for marketing
                purposes. Ever.
              </p>
              <Link to="/service/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    meet our team
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="col-sm-6"></div>
          </div>
        </div>
      </section>
      <section className="section-image-span">
        <div className="section-image-span--img">
          <div className="section-col-1">
            <StaticImage
              src="../images/bg/infographic-home.svg"
              formats={["png"]}
            />
          </div>
        </div>
      </section>
      <section className="section-blocks-6">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="side-binder">
                <h6>INDUSTRIES</h6>
                <h3>SECURITY</h3>
              </div>
            </div>
            <div className="section-col-2">
              <IndustriesStatic />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h5>
                  “I love having Vigilant on our side because we need people who
                  eat, breathe and sleep security and your team does. Your
                  detection rate is amazing. You find threats the same day they
                  occur. When Vigilant calls, I know to pick up."
                </h5>
                <hr />
                <h6>- GOVERNMENT CLIENT</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-resources">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>RESOURCES</h3>
            </div>
            <div className="section-col-2">
              <ul className="resources-list">
                {resourcesList.map(data => {
                  return (
                    <FooterRecCard
                      altTxt={data.title}
                      title={data.title}
                      subTitle={data.subtitle}
                      recCat={data.cat}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default ForSecurity
